<script setup lang='ts'>
const props = withDefaults(defineProps<{
    dark?: boolean,
    light?: boolean
}>(), {
    dark: false,
    light: false
});

const classes = computed(() => {
    return {
        'bg-white': props.light,
        'bg-gray-200': props.dark,
    }
})
</script>

<template>
    <div :class="classes" class="container">
            <slot name="header"/>
            <slot/>
    </div>
</template>

<style scoped lang="scss">

.container {
    padding: 1rem 2rem;
    font-size: 1.1rem;
}

@media (min-width: $md) {
    .container {
        padding: 2rem 10rem;
    }
}

@media (min-width: $lg) {
    .container {
        padding: 2rem 14rem;
    }
}


.bg {
    padding: 1rem 2rem;
    
    &-dark {
        background-color: $bgDarkColor;
    }
    
    &-light {
        background-color: #fff;
    }
    
}
</style>